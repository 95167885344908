import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <noscript
          aria-hidden="true"
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJSTS9Z" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
          }}
        />
        <header className="main-header wrapper">
          <h1 className="logo aligncenter">
            <a href="/">Rovi - Supositorios de glicerina</a>
          </h1>
          <a href="#" className="btn-menu" />
        </header>
        <div className="main-menu">
          <div className="wrapper">
            <div className="row">
              <nav className="main-nav">
                <ul
                  className="inline-list alignleft nice-menu nice-menu-right nice-menu-main-menu"
                  id="nice-menu-1"
                >
                  <li className="menu-218 menu-path-front first odd ">
                    <a href="/" title>
                      Inicio
                    </a>
                  </li>
                  <li className="menu-308 menu-path-node-1  even ">
                    <a href="/por-que-rovi/">Nuestra marca</a>
                  </li>
                  <li className="menu-313 menuparent  menu-path-node-2  odd ">
                    <a href="/supositorios-de-glicerina/">
                      Supositorios
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-314 menu-path-node-3 first odd ">
                        <a href="/supositorios-de-glicerina/que-son/">
                          ¿Qué son los supositorios de glicerina?
                        </a>
                      </li>
                      <li className="menu-315 menu-path-node-4  even ">
                        <a href="/supositorios-de-glicerina/origen-vegetal/">
                          La Glicerina: origen vegetal
                        </a>
                      </li>
                      <li className="menu-316 menu-path-node-5  odd last">
                        <a href="/supositorios-de-glicerina/ventajas/">
                          Ventajas
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-317 menuparent  menu-path-node-6  even ">
                    <a href="/productos/">
                      Productos
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-319 menu-path-node-7 first odd ">
                        <a href="/productos/rovi-adultos/">Adultos</a>
                      </li>
                      <li className="menu-318 menu-path-node-8  even ">
                        <a href="/productos/rovi-ninos/">Niños</a>
                      </li>
                      {/* <li className="menu-320 menu-path-node-9  odd last">
                        <a href="/productos/">Lactantes</a>
                      </li> */}
                    </ul>
                  </li>
                  <li className="menu-349 menuparent  menu-path-node-10  odd ">
                    <a href="/estrenimiento/">
                      Estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-350 menu-path-node-11 first odd ">
                        <a href="/estrenimiento/que-es/">¿Qué es?</a>
                      </li>
                      <li className="menu-351 menu-path-node-12  even ">
                        <a href="/estrenimiento/tipos/">
                          Tipos de estreñimiento
                        </a>
                      </li>
                      <li className="menu-352 menu-path-node-13  odd ">
                        <a href="/estrenimiento/causas/">
                          Causas del estreñimiento
                        </a>
                      </li>
                      <li className="menu-353 menu-path-node-14  even last">
                        <a href="/estrenimiento/mitos/">Mitos</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-354 menuparent  menu-path-node-15  even last">
                    <a href="/ninos/">
                      Los niños y el estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-360 menuparent  menu-path-node-21 first odd ">
                        <a href="/ninos/estrenimiento-lactantes/">
                          Lactantes
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-361 menu-path-node-22 first odd ">
                            <a href="/ninos/estrenimiento-lactantes/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-362 menu-path-node-23  even ">
                            <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-363 menu-path-node-24  odd ">
                            <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-364 menu-path-node-25  even last">
                            <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-355 menuparent  menu-path-node-16  even last">
                        <a href="/ninos/estrenimiento-infantil/">
                          Niños
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-356 menu-path-node-17 first odd ">
                            <a href="/ninos/estrenimiento-infantil/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-357 menu-path-node-18  even ">
                            <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-358 menu-path-node-19  odd ">
                            <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-359 menu-path-node-20  even last">
                            <a href="/ninos/estrenimiento-infantil/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="breadcrumbs">
          <div className="wrapper">
            <div className="row">
              <ul className="inline-list l-grid-9">
                <li>
                  <a href="/">Inicio</a>
                </li>
                <li>Política de privacidad</li>
              </ul>{" "}
            </div>
          </div>
        </div>
        <div className="main-content wrapper">
          <div className="l-grid-9">
            <p>
              JNTL Consumer Health (Spain), S.L. se preocupa por su privacidad y desea que esté familiarizado con cómo recopilamos, utilizamos y divulgamos información.  Esta Política de privacidad describe nuestras prácticas en relación con la información que nosotros o nuestros proveedores de servicios recopilan a través del sitio web o aplicación (en adelante el “Servicio”) operados y controlados por nosotros, desde los cuales usted accede a esta Política de privacidad.  Al proporcionarnos información personal o utilizar el Servicio, usted declara que ha leído y comprendido esta Política de privacidad.
            </p>
            <p>
              <strong>USO POR PARTE DE MENORES DE EDAD</strong>
            </p>
            <p>
              El Servicio no está dirigido a personas menores de 14 años, y le pedimos a estas personas que no proporcionen información personal en el Servicio. Si su hijo ha enviado Información personal y usted desea solicitar que dicha Información personal sea eliminada, por favor contáctenos tal y como se explica más adelante en la sección Contactar con nosotros.
            </p>
            <p>
              <strong>RECOGIDA DE INFORMACIÓN</strong>
            </p>
            <p>
              Podemos solicitarle que envíe información personal para que se beneficie de ciertas características (como suscripciones a boletines, consejos/indicadores o procesamiento de pedidos) o para participar en una actividad particular (como sorteos u otras promociones).  Se le comunicará qué información es necesaria y cuál es opcional.
            </p>
            <p>
            Podremos combinar la información que usted proporcione con otros datos que hayamos obtenido de usted, ya sea en línea o no, incluido, por ejemplo, su historial de compra.  También podemos combinarla con información sobre usted que recibamos de otras filiales de Kenvue. 
            </p>
            <p>
              Si nos envía cualquier información personal relativa a otro individuo, usted declara que tiene autorización para hacerlo y para permitirnos usar la información de acuerdo con esta Política de privacidad.
            </p>
            <p>
              <strong>INFORMACIÓN SENSIBLE</strong>
            </p>
            <p>
              Salvo que se lo solicitemos o se le invite específicamente, le pedimos que no nos envíe ni divulgue información personal sensible (p. ej., número de la seguridad social, información relacionada con el origen racial o étnico, opiniones políticas, religión u otras creencias, salud o afecciones médicas, vida sexual u orientación sexual, antecedentes penales o asociación sindical, o datos genéticos o biométricos) en el Servicio o a través de este, o de otro modo a nosotros.
            </p>
            <p>
              <strong>USO Y RECOPILACIÓN AUTOMÁTICA DE INFORMACIÓNM</strong>
            </p>
            <p>
              Nosotros y nuestros proveedores de servicios podemos recopilar cierta información de forma automática mientras navega por el Servicio.  Lea la{" "}
              <a href="/politica-cookies/" target="_blank">
                Política de cookies
              </a>{" "}
              para obtener información detallada sobre las cookies y otras tecnologías de rastreo empleadas en el Servicio. Excepto cuando lo permita la ley aplicable, instalaremos las cookies una vez hayamos recibido su consentimiento mediante el banner de cookies o su centro de preferencias. La Política de cookies incluye información sobre cómo puede desactivar estas tecnologías.
            </p>
            <p>
              Nosotros y nuestros proveedores de servicios también podemos usar y recopilar automáticamente información de las siguientes formas:
            </p>
            <p>
              <strong>A través de su navegador:</strong> La mayoría de los navegadores recopilan cierta información, como su dirección de Control de Acceso a Medios (Media Access Control, MAC), tipo de ordenador (Windows o Mac), resolución de pantalla, nombre y versión del sistema operativo, y el tipo y la versión del navegador de Internet.  Podremos recopilar información similar, como su tipo de dispositivo e identificador, si accede al Servicio a través de un dispositivo móvil.  Utilizamos esta información para asegurar que el Servicio funcione adecuadamente.
            </p>
            <p>
              <strong>Dirección IP:</strong> Su dirección IP es un número que su Proveedor de Servicios de Internet (Internet Service Provider, ISP) asigna automáticamente a su ordenador.  La dirección IP se identifica y registra automáticamente en los archivos de registro de nuestro servidor cada vez que un usuario visita el Servicio, junto con la hora de su visita y las páginas que visitó.  La recogida de direcciones IP es una práctica estándar y muchos servicios en línea la realizan de forma automática.  Usamos las direcciones IP para calcular los niveles de uso del Servicio, diagnosticar problemas en servidores y administrar el Servicio.  También podemos deducir su ubicación aproximada desde su dirección IP.
            </p>
            <p>
              <strong>Información de dispositivos:</strong> Podemos recopilar información sobre su dispositivo móvil, como el identificador único de dispositivo, para entender cómo utiliza el servicio.
            </p>
            <ul />
            <p>
              <strong>CÓMO USAMOS Y DIVULGAMOS LA INFORMACIÓN</strong>
            </p>
            <p>
              Usamos y divulgamos la información que nos proporciona según lo mencionado en el momento de la recopilación.  Por favor consulte la sección titulada “Opciones y accesos” más adelante para saber cómo puede quedar excluido de ciertos de nuestros usos y divulgaciones.
            </p>
            <p>
              Cuando lo requiera la legislación aplicable, obtendremos su consentimiento para el uso que hagamos de su información personal en el momento de la recopilación de información. Asimismo, podemos usar información suya o sobre usted tal y como sea necesario para ejecutar un contrato, cumplir una obligación legal (por ejemplo, debido a nuestras obligaciones sobre farmacovigilancia) o para nuestros intereses comerciales legítimos.  También podemos basarnos en otras bases legales, específicamente para:
            </p>
            <ul>
              <li>
                <strong>
                  Proporcionar la funcionalidad del Servicio y atender sus
                  solicitudes.
                </strong>
                <ul>
                  <li>
                    o	para proporcionarle la funcionalidad del Servicio y proporcionarle un servicio al cliente relacionado;
                  </li>
                  <li>
                    o	para responder a sus consultas y atender sus solicitudes, como envío de documentos que solicite o alertas por correo electrónico;
                  </li>
                </ul>
                <p>
                  o	para enviarle información importante sobre nuestra relación con usted o sobre el Servicio, cambios en nuestros términos, condiciones y políticas y/o información administrativa.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Lograr nuestros fines comerciales.</strong>
              </li>
            </ul>
            <ul>
              <li>
                para el análisis de datos, por ejemplo, para mejorar la
                eficiencia del Servicio;
              </li>
              <li>
                para auditorías, para verificar que nuestros procesos internos
                funcionan según lo previsto y que cumplen con los requisitos
                legales, normativos o contractuales;
              </li>
              <li>
                para fines de supervisión de fraude y seguridad, por ejemplo,
                para detectar y prevenir ataques cibernéticos o intentos de
                cometer robos de identidad;
              </li>
              <li>para desarrollar nuevos productos y servicios;</li>
              <li>
                para potenciar, mejorar o modificar nuestro sitio web o
                productos y servicios;
              </li>
              <li>
                para identificar las tendencias de uso del Servicio, por
                ejemplo, comprender qué partes de nuestro Servicio son de mayor
                interés para los usuarios; y
              </li>
              <li>
                para determinar la efectividad de nuestras campañas
                promocionales, para que podamos adaptar nuestras campañas a las
                necesidades e intereses de nuestros usuarios.
              </li>
            </ul>
            <p>
              Participaremos en estas actividades para administrar nuestra
              relación contractual con usted, cumplir con una obligación legal
              y/o porque tenemos un interés legítimo.
            </p>
            <ul>
              <li>
                <strong>
                  Análisis de información personal para proporcionar servicios
                  personalizados.
                </strong>
                <ul>
                  <li>
                    para comprenderle mejor, de modo que podamos personalizar
                    nuestras interacciones con usted y proporcionarle
                    información y/u ofertas adaptadas a sus intereses;
                  </li>
                  <li>
                    para comprender mejor sus preferencias de modo que podamos
                    entregar contenido a través del Servicio que creemos será
                    relevante e interesante para usted.
                  </li>
                </ul>
                <p>
                  Proporcionaremos servicios personalizados con su
                  consentimiento o porque tenemos un interés legítimo.
                </p>
              </li>
            </ul>
            <p>
              También divulgamos información recopilada a través del Servicio:
            </p>
            <ul>
              <li>
                a nuestras filiales para los fines descritos en esta Política de privacidad.  Puede encontrar una lista de nuestras filiales en{" "}
                <a target="_blank" href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx">
                  https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                </a>{" "}
                - acceder a los documentos presentados por Kenvue ante la Securities Exchange Commission.  JNTL Consumer Health (Spain), S.L. se responsabiliza de la administración de la información personal tratada conjuntamente;
              </li>
              <li>
                a nuestros socios externos con los que ofrecemos promociones de marcas compartidas o comercialización conjunta;;
              </li>
              <li>
                a nuestros proveedores de servicios externos que proporcionan servicios, como hospedaje y moderación web, hospedaje de aplicaciones para móviles, análisis de datos, procesamiento de pagos, envío de pedidos, provisión de infraestructura, servicios de TI, servicio al cliente, servicios de provisión de correo electrónico y correo directo, auditoría y otros servicios, a fin de permitirles proporcionar servicios; y
              </li>
              <li>
                tal y como permita la legislación aplicable, a un tercero en caso de restructuración, fusión, venta, unión de sociedades, sesión, transferencia u otra distribución de cualquier parte de nuestro negocio, activo o acción (incluyendo procedimientos de quiebra o similares).
              </li>
            </ul>
            <p>
              Además, podemos emplear y divulgar su información según consideremos necesario o adecuado: (a) para cumplir con procesos legales o con la legislación aplicable, que puede incluir leyes fuera de su país de residencia; (b) como permita la legislación aplicable, para responder ante solicitudes de las autoridades públicas y gubernamentales, que pueden tratarse de autoridades fuera de su país de residencia; (c) para hacer cumplir nuestros términos y condiciones; y (d) para proteger nuestros derechos, privacidad, seguridad o propiedad o los de nuestras filiales, los de usted u los de otros.  También podemos usar y divulgar su información de otras formas tras obtener su consentimiento para ello
            </p>
            <p>
              Podemos usar y divulgar información que recopilemos automáticamente tal y como se describe anteriormente en “Uso y Recopilación automática de información”.
            </p>
            <p>
              Además, cuando lo permita la legislación aplicable, podemos usar y divulgar información no personal con cualquier fin.  Si combinamos información personal en estado no identificable con información que sí es identificable (como combinar su nombre con su ubicación geográfica), trataremos la información combinada como personal mientras esté combinada.
            </p>
            <div>
              <strong>OPCIONES Y ACCESO </strong>
              <p>
                <strong>
                  Sus opciones sobre el uso y la divulgación de su información
                  personal
                </strong>
              </p>
              <p>
                Le proporcionamos opciones sobre el uso y la divulgación de su información personal con fines de marketing.  Usted puede optar por lo siguiente:
              </p>
              <ul>
                <li>
                  <strong>Recibir nuestras comunicaciones comerciales:</strong>{" "}
                  Si no desea recibir más nuestras comunicaciones comerciales en el futuro, podrá oponerse a su recepción contactándonos por correo electrónico haciendo{" "}
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                    target="_blank"
                  >
                    click aquí
                  </a>
                  .  En su solicitud, le rogamos que facilite su nombre, identifique la(s) modalidad(es) de comunicaciones de marketing que no desea recibir e incluya la(s) dirección/direcciones a la(s) que se la(s) envió/enviaron.  Por ejemplo, si no desea recibir nuestras comunicaciones comerciales por correo electrónico o correo directo, indíquelo y facilite su nombre así como correo electrónico o dirección postal.  Además, puede oponerse a recibir nuestras comunicaciones comerciales por correo electrónico siguiendo las instrucciones de cancelación de la suscripción, que se proporcionan en el mensaje.
                </li>
                <li>
                  <strong>Recepción de nuestros recordatorios:</strong>{" "}Si no desea recibir más recordatorios médicos de nosotros en el futuro, podrá oponerse a su recepción contactándonos por correo electrónico haciendo{" "}
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                    target="_blank"
                  >
                    click aquí
                  </a>
                  .  En su comunicación, facilite su nombre y dirección de correo electrónico o número de teléfono en el que recibe los recordatorios.
                </li>
                <li>
                  <strong>
                    Comunicación de su información personal a filiales y socios
                    externos:
                  </strong>{" "}
                  Si optó previamente por recibir comunicaciones comerciales de nuestras filiales o socios externos, usted puede oponerse a que compartamos su información personal con dichas partes para fines publicitarios en el futuro contactándonos por correo electrónico haciendo{" "}
                  <a
                    href=" https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                    target="_blank"
                  >
                    click aquí
                  </a>
                  .  En su comunicación, le rogamos que nos indique que no debemos comunicar su información personal a nuestras filiales y/o socios externos con fines publicitarios, e incluya su nombre y dirección de correo electrónico.
                </li>
              </ul>
              <p>
                Trataremos de cumplir con su(s) solicitud(es) lo antes posible.  Cabe señalar que si se opone tal como se describió anteriormente, no podremos eliminar su información personal de las bases de datos de nuestras filiales con las que ya hayamos compartido su información personal (es decir, a la fecha de su solicitud de oposición). No obstante, haremos esfuerzos razonables para informar a nuestras filiales de su solicitud. Tenga en cuenta que, si se opone a recibir comunicaciones comerciales de nosotros, sigue siendo posible que le enviemos importantes comunicaciones administrativas y sobre transacciones, respecto de los cuales no puede oponerse.
              </p>
              <p>
                <strong>
                  Cómo puede acceder, cambiar o eliminar su información personal
                </strong>
              </p>
              <p>
                Si usted quisiera revisar, corregir, actualizar, limitar o eliminar su información personal, o si quisiera solicitar una copia electrónica de su información personal con la finalidad de transmitirla a otra empresa (hasta donde la legislación aplicable le conceda estos derechos) contáctenos por correo electrónico haciendo{" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                  target="_blank"
                >
                  click aquí
                </a>
                .  Responderemos a su solicitud tan pronto como sea razonablemente posible y no más tarde de un mes tras la recepción. Para ayudarle en caso de demora en nuestra respuesta, se le notificará rápidamente y se le proporcionará la fecha en la que le daremos respuesta.
              </p>
              <strong>TRANSFERENCIA INTERNACIONAL</strong>
              <p>
                Su información personal podrá almacenarse y procesarse en cualquier país en que tengamos instalaciones o proveedores de servicios, y al usar nuestro Servicio o proporcionarnos su consentimiento (cuando la ley lo exija), su información podrá ser transferida a países fuera de su país de residencia, incluyendo los Estados Unidos, que podrán establecer diferentes normas de protección de datos distintas a las de su país.  Sin embargo, existen medidas contractuales apropiadas y de otro tipo implementadas para proteger la información personal cuando se transfiere a nuestras filiales o socios externos en otros países.
              </p>
              <p>
                La Comisión Europea reconoce a algunos países fuera del Espacio Económico Europeo (EEE) como países que proporcionan un nivel de protección de datos adecuado de acuerdo con los estándares del EEE (la lista completa de estos países está disponible{" "}
                <a
                  href="https://ec.europa.eu/info/law/law-topic/data-protection/adequacy-decisions_es"
                  target="_blank"
                >
                  aquí
                </a>
                ). En el caso de transferencias desde el EEE a países considerados no adecuados por la Comisión Europea, nos hemos asegurado de que existan medidas adecuadas implementadas, incluyendo la garantía de que el receptor esté vinculado por cláusulas contractuales tipo de la UE, para proteger su Información personal.  Usted puede obtener una copia de estas medidas contactando con nuestro Delegado de Protección de Datos, de acuerdo con las instrucciones en la sección “Contactar con nosotros” (Contacting Us) abajo.
              </p>
              <strong>SEGURIDAD</strong>
              <p>
                Buscamos implementar medidas razonables organizativas, técnicas y administrativas diseñadas para proteger la información personal bajo nuestro control.  Lamentablemente, no se puede garantizar la seguridad total de ninguna transmisión de datos por Internet o de sistemas de almacenamiento de datos.  Si tiene motivos para creer que su interacción con nosotros ya no es segura (por ejemplo, si considera que la seguridad de una cuenta que tiene con nosotros ha sido comprometida), le rogamos que nos lo notifique de inmediato de acuerdo con las instrucciones en la sección “Contactar con nosotros” (Contacting Us) abajo.
              </p>
              <strong>PERIODO DE RETENCIÓN</strong>
              <p>
                Retendremos su información personal durante el tiempo que sea necesario o esté permitido de acuerdo con el fin o los fines para los que se obtuvo.  Los criterios utilizados para determinar nuestros periodos de retención incluyen: (i) el periodo de tiempo por el que tenemos una relación con usted y le proporcionamos el Servicio; (ii) si existe una obligación legal a la que estamos sometidos; y (iii) si la retención es aconsejable teniendo en cuenta nuestra posición legal (lo relativo a los plazos aplicables de prescripción, litigio o investigaciones reguladoras).
              </p>
              <strong>SITIOS Y SERVICIOS DE TERCEROS</strong>
              <p>
                El Servicio puede contener vínculos a sitios de terceros. Esta Política de privacidad no trata ni tampoco nos responsabilizamos por la privacidad, información o prácticas de terceros, incluidos terceros que operan cualquier sitio o servicio en línea (entre otros, cualquier aplicación) que esté disponible a través de este Servicio o de un enlace incluido en este Servicio.  La disponibilidad de cualquier sitio o presencia en el Servicio, o la inclusión de un enlace a estos, no implica su aprobación por nosotros o nuestras filiales
              </p>
              <strong>CONTÁCTENOS</strong>
              <p>
                JNTL Consumer Health (Spain), S.L., en el Paseo de las Doce Estrellas, 5-7, 28042 Madrid, es la empresa responsable de la recopilación, uso y divulgación de información personal de acuerdo con esta Política de privacidad.
              </p>
              <p>
                Si tiene alguna pregunta sobre esta Política de privacidad, contáctenos por correo electrónico haciendo{" "}
                <a
                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL#etd=::00c?Z9W00Y00r_800ww$"
                  target="_blank"
                >
                  click aquí
                </a>
                , o por correo postal a la siguiente dirección:
              </p>
              <p>
                <br /> Servicio de Atención al Consumidor
                <br /> División Consumo
                <br /> Paseo de las Doce Estrellas, 5-7
                <br /> 28042 Madrid.
              </p>
              <p>
                También puede ponerse en contacto con el Delegado de Protección
                de Datos a cargo de su país o región, si procede, a través de{" "}
                <a href="mailto:emeaprivacy@kenvue.com">
                  <strong>emeaprivacy@kenvue.com</strong>.
                </a>
              </p>
              <p>
                <strong>
                  PRESENTAR UNA RECLAMACIÓN ANTE LA AUTORIDAD REGULADORA
                </strong>
              </p>
              <p>
                Usted puede presentar una reclamación ante una autoridad supervisora competente en su país o región.  Por favor haga clic{" "}
                <a
                  href="https://edpb.europa.eu/about-edpb/board/members_es"
                  target="_blank"
                >
                  aquí
                </a>{" "}
                para acceder a la información de contacto de dichas autoridades.
              </p>
              <p>
                <strong>ACTUALIZACIONES DE ESTA POLÍTICA DE PRIVACIDAD</strong>
              </p>
              <p>
                Podremos modificar esta Política de privacidad.  Toda modificación de nuestra Política de privacidad entrará en vigor con la publicación de la Política de privacidad revisada en el Servicio.  Si utiliza el Servicio después de estas modificaciones, esto significa que acepta la Política de Privacidad revisada.  Recomendamos que revise la Política de Privacidad de forma regular cuando visite el Servicio. Esta Política se actualizó por última vez el 04/05/2023
              </p>
            </div>
          </div>
        </div>
        <div className="legales">
          <nav className="wrapper">
            <ul className="inline-list aligncenter">
              <li>
                <a href="/aviso-legal/">Aviso legal</a>
              </li>
              <li>
                <a href="/politica-de-privacidad/">Política de privacidad</a>
              </li>
              <li>
                <a href="/politica-cookies/">Política de cookies</a>
              </li>
              <li>
                <a href="/mapa-del-sitio/">Mapa del sitio</a>
              </li>
              <li>
                <a href="/contacto/">Contacto</a>
              </li>
              <li>
                <a href="/preguntas-frecuentes-estrenimiento/">FAQs</a>
              </li>
              <li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">
                  Configuración de cookies
                </a>
              </li>
            </ul>{" "}
          </nav>
        </div>
        <footer className="wrapper">
          <nav className="footer-seo row">
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/por-que-rovi/">Nuestra marca</a>
                </li>
                <li className="vs-3">
                  <a href="/supositorios-de-glicerina/">Supositorios</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/supositorios-de-glicerina/que-son/">
                        ¿Qué son los supositorios de glicerina?
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/origen-vegetal/">
                        La glicerina: origen vegetal
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/ventajas/">
                        Ventajas
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/productos/">Productos</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/productos/rovi-adultos/">Adultos</a>
                    </li>
                    <li>
                      <a href="/productos/rovi-ninos/">Niños</a>
                    </li>
                    {/* <li>
                      <a href="/productos/">Lactantes</a>
                    </li> */}
                  </ul>
                </li>
                <li className="vs-3">
                  <a href="/estrenimiento/">estreñimiento</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/estrenimiento/que-es/">¿Qué es?</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/tipos/">Tipos</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/causas/">Causas</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/mitos/">Mitos</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/ninos/">Los niños y el estreñimiento</a>
                  <ul className="nivel-1">
                    <li>
                      <a href="/ninos/estrenimiento-infantil/">Niños</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-infantil/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/ninos/estrenimiento-lactantes/">Lactantes</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="copy l-grid-3 m-full-width">
              <img
                src="/sites/all/themes/custom/rovi/images/html/logo-footer.png"
                alt="logo Rovi"
              />
              <p className="vs-2">
                <strong>©JNTL Consumer Health (Spain), S.L.</strong>
              </p>
              <p className="vs-2">
                Este sitio web está publicado
                <br className="m-hidden" />
                por JNTL Consumer Health (Spain), S.L.
                <br className="m-hidden" />
                único responsable de su contenido.
                <br />
                Está dirigida a visitantes de España.
              </p>
              <p className="semi-bold vs-2">Última actualización: marzo 2024</p>
              {/* <p>
                <img
                  src="/sites/all/themes/custom/rovi/images/html/logo-anefp.png"
                  alt="logo anefp"
                  className="logo-anefp vs-3"
                />
              </p> */}
            </div>
          </nav>
        </footer>
        <div className="instructions">
          <div className="wrapper">
            <ul className="aligncenter">
              <li>
                <img src="/sites/all/themes/custom/rovi/images/html/ico-instrucciones-lea.png" />{" "}
                Lea las instrucciones
              </li>
              <li>
                <img src="/sites/all/themes/custom/rovi/images/html/ico-instrucciones-medicamento.png" />{" "}
                de este medicamento y
              </li>
              <li>
                <img src="/sites/all/themes/custom/rovi/images/html/ico-instrucciones-farmaceutico.png" />{" "}
                consulte al Farmacéutico
              </li>
            </ul>
          </div>
        </div>
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery-ui.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/picturefill.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/main.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nvar scripts_loaded = false;\nfunction setCookie(name , state, days) {\ndate = new Date();\ndate.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));\nexpiresDate = date.toGMTString();\nwindow.document.cookie = name +'='+ state +'; expires=' + expiresDate + \"; path=/\";\n}\nfunction loadScripts() {\nscripts_loaded = true;\n}\nfunction jnjAcceptCookiesPolicy( e ) {\nsetCookie('jnj-cookies-policy', 'true', 1000);\nloadScripts();\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n}\n$(document).ready(function() {\n$(document).one( 'click', '#btn-accept-cookies-box', jnjAcceptCookiesPolicy );\n$(document).one('click', '#btn-close-cookies-box', function() {\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n});\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
